/* src/index.css */
@import "~bootstrap/dist/css/bootstrap.min.css";

body {
  background: #fff;
  font-family: Montserrat,sans-serif;
  font-weight: 300;
  line-height: 1.4
}

a,body {
  color: #3d3935
}

a {
  background: transparent
}

.custom-row {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.input-search {
  border: none !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-radius: inherit;
  border: 1.5px solid #283E66;
  border-radius: 20px;
}

.icon-search{
  background-color: #283E66 !important;

  border: none !important;
}

.llegada{
  color: #E4000B;
  text-decoration: none;
  font-weight: bold;
  padding-bottom: 15px;
  padding: 10px;
}

.active>.page-link, .page-link.active, .page-link:focus {
  z-index: 1;
  color: #fff !important;
  background-color: #E4000B;
  border-color: #E4000B;
}

/*
.gm-style-iw-a {
  display: none !important; 
}
*/
.info-window-content .gm-ui-hover-effect {
  display: none !important;
}


.llegada:focus,
.llegada:active {
    outline: none; /* Elimina el contorno de enfoque y activo */
}

.gm-style .gm-style-iw-c {
  padding: 0px !important;

}

.gm-style-iw-d{
  overflow:auto !important;
}



.card {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

.card-footer {
  background-color: #f8f9fa;
}


.icon-label svg {
  margin-right: 5px; 
}

.links{
  display: flex;
  justify-content: center; 
  align-items: center; 

  color: #E4000B;
  text-decoration: none;
  font-weight: bold;
  padding-bottom: 15px;
  flex-wrap: wrap;
} 


.links a {
  text-decoration: none;
  display: block;
  color: #E4000B;
  padding: 3px;
}

.links a:focus {
  outline: none; /* Eliminar el contorno de foco */
}


@media (max-width: 400px) {
  .links {
    flex-direction: column;
  }
}

.linksCard{
  display: flex;
  justify-content: center; 
  align-items: center; 
  flex-wrap: wrap;
  color: #E4000B;
  text-decoration: none;
  padding-bottom: 15px;
}

.linksCard a {
  text-decoration: none;
  display: block;
  color: #E4000B;
  padding-right: 6px;
}

@media (max-width: 400px) {
  .linksCard {
    flex-direction: column;
  }
}


.schedule-list {
  display: flex;
  flex-wrap: wrap;
}

.schedule-list span {
  margin-right: 10px;
}



/* START */

.custom-accordion {
  background-color: #f7f7f7 !important; 
  justify-content: center; 
  align-items: center; 
  flex-wrap: wrap;
  text-decoration: none;
  color: #283E66;
  width: 100%;
}

.accordion-button{
  color: #283E66;
  padding: 5px;
  background-color: #f7f7f7 !important; 
}

.accordion-button:focus {
  box-shadow: none !important; 
 
}

.accordion-body{
  padding-left: 2px;
  padding-right: 2px;
}


/* END */

.m-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.m-date-day {
  margin-right: 10px; /* Espacio entre el día y la hora */
}

.m-date-hour {
  display: flex;
  flex-wrap: wrap; /* Envolver las horas si el espacio es insuficiente */
}

.m-date-hour span {
  margin-right: 5px; /* Espacio entre las horas */
}

.highlight {
  background: linear-gradient(205.97deg, #203760 38.08%, #004eb8);
  
  color: #fff ;
  border-radius: 5px;
}